//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-452:_728,_1188,_6876,_2316,_2861,_2780,_6484,_8664;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-452')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-452', "_728,_1188,_6876,_2316,_2861,_2780,_6484,_8664");
        }
      }catch (ex) {
        console.error(ex);
      }